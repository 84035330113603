import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/seo'

import { BasicPageContain, CopyBlockStyle } from '../utils/copperTypography';


const NotFoundPage = () => (
  <BasicPageContain>
    <SEO title="404: Page not found" />
    <CopyBlockStyle>
      <h3>Error</h3>
      <h1>Page Not Found</h1>
      <h2>Please excuse our error.</h2>
      <p className='wide'>We could not find the page you requested.<br />Please <Link to="/contact">Contact Us</Link> if you need any assistance.<br /><br />Thank You.</p>
    </CopyBlockStyle>
  </BasicPageContain>
)

export default NotFoundPage
